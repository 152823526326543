<template>
  <div>
    <!-- 搜索 -->
    <a-card class="search-bar">
      <a-form-model ref="searchForm" :model="searchForm">
        <a-row :gutter="16">
          <a-col :span="6">
            <a-form-model-item label="公司名称" prop="company_name">
              <a-input 
                v-model.trim="searchForm.company_name" 
                placeholder="模糊查询" 
                allow-clear
                @keyup.native.enter="search()"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item>
              <a-button
                :loading="loading" 
                type="primary" 
                @click="search()">查 询</a-button>
                <a-button @click="reset()">重 置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>
    <!-- 内容 -->
    <a-card class="main-content" >
      <template slot="title">
        <span class="table-title">公司管理</span>
        <a-button type="primary" icon="plus" @click="showModal('add')">新增</a-button>
      </template>
      <a-table 
        :loading="loading"
        rowKey="id"
        :columns="columns"
        :data-source="datalist"
        :pagination="pagination"
        @change="onPageChange">
        <!-- 状态栏 -->
        <span slot="status" slot-scope="text, record">
          <a-switch 
            :checked="text == 1"
            checkedChildren="启用"
            unCheckedChildren="停用"
            @change="(checked, event) => switchStatus(checked, event, record)"></a-switch>
        </span>
        <!-- 操作栏 -->
        <span slot="action" slot-scope="text, record, index">
          <a-button type="link" @click="showModal('edit', record.id)">编辑</a-button>
          <a-button :disabled="record.is_locked == 1" type="link" @click="clearData(record.id)">清除数据</a-button>
        </span>
      </a-table>
    </a-card>
    <!-- 弹框 -->
    <a-modal
      :title="modalType == 'add' ? '新增公司' : '修改公司信息'"
      :width="650"
      :visible="dialog"
      :confirm-loading="confirmLoading"
      @ok="modalConfirm()"
      @cancel="dialog = false">
      <a-spin :spinning="spinning">
        <a-form-model
          ref="modalForm"
          :model="form"
          :rules="rules"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          class="modal-form">
          <a-form-model-item label="公司名称" prop="company_name">
            <a-input 
              v-model.trim="form.company_name"
              allow-clear
              placeholder="公司名称"/>
          </a-form-model-item>
          <a-form-model-item label="统一社会信用代码" prop="company_number">
            <a-input 
              v-model.trim="form.company_number"
              allow-clear
              placeholder="18位统一社会信用代码"
              :max-length="18"/>
          </a-form-model-item>
          <a-form-model-item label="平台访问地址" prop="company_url">
            <a-input 
              v-model.trim="form.company_url"
              allow-clear
              placeholder="平台访问地址"/>
          </a-form-model-item>
          <a-row>
            <a-col :span="12">
              <a-form-model-item 
                label="负责人" 
                :wrapper-col="{ span: 12 }" 
                prop="contact_name">
                <a-input 
                  v-model.trim="form.contact_name"
                  allow-clear
                  placeholder="负责人"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item 
                label="联系电话" 
                :wrapper-col="{ span: 12 }" 
                prop="contact_tel">
                <a-input 
                  v-model.trim="form.contact_tel"
                  allow-clear
                  placeholder="联系电话"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item 
                label="账号名" 
                :wrapper-col="{ span: 12 }" 
                prop="user_name">
                <a-input 
                  v-model.trim="form.user_name" 
                  allow-clear
                  placeholder="最少为6位"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item 
                label="密码" 
                :wrapper-col="{ span: 12 }" 
                :prop="modalType == 'add' ? 'password': ''">
                <a-input-password  
                  v-model.trim="form.password"
                  allow-clear
                  placeholder="长度为6-20"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-model-item 
                label="权限选择" 
                :wrapper-col="{ span: 12 }" 
                prop="rule_id">
                <a-select 
                  v-model="form.rule_id"
                  allow-clear
                  placeholder="选择权限">
                  <a-select-option
                    v-for="item in roles"
                    :key="item.id">
                    {{item.gname}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item 
                label="使用时长" 
                :wrapper-col="{ span: 12 }" 
                prop="exp_date">
                <a-select
                  v-model="form.exp_date"
                  allow-clear
                  placeholder="选择使用时长">
                  <a-select-option :value="1">一年</a-select-option>
                  <a-select-option :value="2">两年</a-select-option>
                  <a-select-option :value="3">三年</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-form-model-item label="备注" prop="remark">
            <a-textarea 
              v-model="form.remark" 
              allow-clear
              placeholder="备注"
              :autoSize="{ minRows: 3}"/>
          </a-form-model-item>
        </a-form-model>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'Company',
  data () {
    return {
      loading: false,
      searchForm: {
        company_name: '',
        page: 1,
        list_rows: 30
      },
      columns: [
        { title: '序号', key: 'id', dataIndex: 'id', align: 'center', width: 65, customRender: (text, record, index) =>`${(this.pagination.current - 1) *this.pagination.pageSize + index + 1}` },
        { title: '公司名称', key: 'company_name', dataIndex: 'company_name', ellipsis: true },
        { title: '平台访问地址', key: 'company_url', dataIndex: 'company_url', ellipsis: true },
        { title: '备注', key: 'remark', dataIndex: 'remark', ellipsis: true },
        { title: '账号', key: 'username', dataIndex: 'username', ellipsis: true },
        { title: '负责人', key: 'contact_name', dataIndex: 'contact_name', ellipsis: true },
        { title: '联系电话', key: 'contact_tel', dataIndex: 'contact_tel', ellipsis: true },
        { title: '权限', key: 'group_name', dataIndex: 'group_name', ellipsis: true },
        { title: '创建时间', key: 'reg_date', dataIndex: 'reg_date', align: 'center', ellipsis: true },
        { title: '状态', key: 'status', dataIndex: 'status', align: 'center', scopedSlots: { customRender: 'status'} },
        { title: '操作', key: 'action', align: 'center', scopedSlots: { customRender: 'action'} }
      ],
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50'],
        current: 1,
        pageSize: 30,
        total: 0,
        showTotal: (total) => `共 ${total} 条`
      },
      datalist: [],
      // 弹框信息
      dialog: false,
      confirmLoading: false,
      modalType: 'add',
      roles: [],  // 权限列表
      init_form: {},
      spinning: false,
      form: {
        company_name: '',
        company_number: '',
        company_url: '',
        contact_name: '',
        contact_tel: '',
        user_name: '',
        password: '',
        rule_id: undefined,
        exp_date: undefined,
        remark: ''
      },
      rules: {
        company_name: [{ required: true, message: '该项为必填项，请填写', trigger: 'blur' }],
        company_number: [
          { required: true, message: '该项为必填项，请填写', trigger: 'blur' },
          { len: 18, message: '长度为18位', trigger: 'blur' }
        ],
        company_url: [{ required: true, message: '该项为必填项，请填写', trigger: 'blur' }],
        contact_name: [{ required: true, message: '该项为必填项，请填写', trigger: 'blur' }],
        contact_tel: [
          { pattern: this.pattern.tel, message: '请填写正确的手机号', trigger: ['blur', 'change'] }
        ],
        user_name: [
          { required: true, message: '该项为必填项，请填写', trigger: 'blur' },
          { pattern: this.pattern.username, message: '用户名最少6位，支持字母和数字', trigger: ['blur', 'change'] }
        ],
        password: [
          { required: true, message: '该项为必填项，请填写', trigger: 'blur' },
          { pattern: this.pattern.password, message: '密码长度为6-20，包含英文，数字', trigger: ['blur', 'change'] }
        ],
        rule_id: [{ required: true, message: '该项为必填项，请选择', trigger: 'change' }],
        exp_date: [{ required: true, message: '该项为必填项，请选择', trigger: 'change' }],
        remark: []
      },
    }
  },
  mounted () {
    this.init_form = {...this.form}
    this.getCompanyList()
  },
  methods: {
    search () {
      this.searchForm.page = 1
      this.pagination.current = 1
      this.getCompanyList()
    },
    reset () {
      this.$refs.searchForm.resetFields();
      this.searchForm.page = 1
      this.searchForm.list_rows = 30
      this.pagination.current = 1
      this.pagination.pageSize = 30
      this.getCompanyList()
    },
    getCompanyList () {
      this.loading = true
      this.api.getCompanyList(this.searchForm).then(res => {
        this.datalist = res.data.data
        this.pagination.total = res.data.total
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    onPageChange (pagination) {
      this.pagination = { ...pagination }
      this.searchForm.page = pagination.current;
      this.searchForm.list_rows = pagination.pageSize;
      this.getCompanyList()
    },
    // 启用 / 停用
    switchStatus (isChecked, event, record) {
      let _self = this
      let msg = isChecked? '您确认要启用该公司吗？' : '您确认要停用该公司吗？';
      this.$confirm({
        title: '友情提示',
        content: msg,
        onOk () {
          return new Promise((resolve, reject) => {
            _self.api.switchCompanyStatus({
              id: record.id,
              status: isChecked ? 1: 0
            }).then(res => {
              _self.$message.success(res.data.msg)
              _self.getCompanyList()
              resolve()
            }).catch(() => {
              reject()
            })
          })
        },
        onCancel () {}
      })
    },
    // 清除数据
    clearData (rowId) {
      let _self = this
      this.$confirm({
        title: '友情提示',
        content: '您即将清除该公司下的数据，是否继续？',
        onOk () {
          return new Promise((resolve, reject) => {
            _self.api.clearCompanyData({id: rowId}).then(res => {
              _self.$message.success(res.data.msg)
              _self.getCompanyList(_self.searchForm)
              resolve()
            }).catch(err => {
              reject()
            })
          })
        },
        onCancel () {}
      })
    },
    showModal (type, companyId) {
      this.modalType = type
      this.dialog = true
      this.allRoles()
      this.$nextTick(() => {
        this.$refs.modalForm.resetFields()
        this.form = this.init_form
        if (companyId) {
          this.getInfo(companyId)
        }
      })
    },
    // 弹框确认
    modalConfirm () {
      this.$refs.modalForm.validate(valid => {
        if (valid) {
          this.confirmLoading = true
          if (this.modalType == 'add') {
            this.add()
          } else {
            this.edit()
          }
        } else {
          return false;
        }
      })
    },
    getInfo (companyId) {
      this.spinning = true
      this.api.companyInfo({id: companyId}).then(res => {
        this.form = res.data.data
        this.spinning = false
      }).catch(err => {
        this.spinning = false
      })
    },
    add () {
      this.api.addCompany(this.form).then(res => {
        this.$message.success(res.data.msg)
        this.dialog =  false 
        this.confirmLoading = false
        this.reset()
      }).catch(() => {
        this.confirmLoading = false
      })
    },
    edit () {
      this.api.editCompany(this.form).then(res => {
        this.$message.success(res.data.msg)
        this.dialog =  false 
        this.confirmLoading = false
        this.getCompanyList()
      }).catch(() => {
        this.confirmLoading = false
      })
    },
    allRoles () {
      this.api.allRoles({category_id: 1}).then(res => {
        this.roles = res.data.data
      })
    },
  }
}
</script>

<style lang="less" scoped>
  .modal-form /deep/ .ant-form-item-label {
    width: 138px;
  }
</style>